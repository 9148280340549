import React from 'react'

function AddressMap() {
    return (
        <div className="google-map-code">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.992138346638!2d90.38834428624031!3d23.878782348463908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c414ffaffb59%3A0x826a5d56aa1f0cb2!2sInCIS!5e0!3m2!1sen!2sbd!4v1657772174492!5m2!1sen!2sbd" width="212" height="100" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}

export default AddressMap