import React from 'react'
import './pixelStarts.css'

function PixelStars() {
    return (
        <>
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
        </>
    )
}

export default PixelStars